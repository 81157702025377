import EquipmentItem from "./EquipmentItem"
const EquipmentList = ({ getListItme , spaceID , itemListData}) => {
    return(
        <>
        {itemListData.length ? itemListData.map((item) => {
          if(item.space == spaceID){
            return <EquipmentItem getListItme={getListItme} key={item.id} item={item} />;
          }
        }) : null}
      </>
    )
}
export default EquipmentList