import { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import api from "../../utils/api";
const LoginForm = () => {
  const history = useHistory()
  const [loadingButton , setLoadingButton] = useState(false)
  const [error, setError] = useState({
    password: false,
    username: false,
  });
  const [info, setInfo] = useState({
    password: "",
    username: "",
  });

  const removeError = (e) => {
    switch (e.target.name) {
      case "username":
        setError((prev) => {
          return {
            ...prev,
            ["username"]: false,
          };
        });
        break;
      case "password":
        setError((prev) => {
          return {
            ...prev,
            ["password"]: false,
          };
        });
        break;
      default:
        break;
    }
  };
  const changeInfo = (e) => {
    setInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const submitForm = (e) => {
    e.preventDefault();
    let valid = true;
    if (info.password.trim() === "") {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          ["password"]: true,
        };
      });
    }
    if (info.username.trim() === "") {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          ["username"]: true,
        };
      });
    }
    if(valid){
        getToken()
    }
  };

  const getToken = async () => {
    setLoadingButton(true)
      let data = {
        email : info.username,
        password : info.password,
      };
      const getToken = await api.call({
        name: "getToken",
        data: data,
      });
      setLoadingButton(false)
      if (getToken.status == 200) {
        localStorage.setItem("token", getToken.data.access);
        localStorage.setItem("refresh", getToken.data.refresh);
        localStorage.setItem("ExpToken" , jwtDecode(getToken.data.access).exp);
        localStorage.setItem("ExpRefresh" , jwtDecode(getToken.data.refresh).exp);
        history.push("/");
      }
  }
  return (
    <Fragment>
      <form onSubmit={submitForm}>
      <div className="mb-14">
        <div className="mb-7 relative">
          <input
            value={info.username}
            onChange={changeInfo}
            onBlur={removeError}
            name="username"
            type="text"
            placeholder="Email..."
            className={`bg-white rounded-md w-full outline-none py-4 px-4 border-2 ${
              error.username ? "border-red-400 border-2" : null
            }`}
          />
          <img
            className="absolute top-[1.15rem] right-3 w-[20px]"
            src="/calculation/static/usernameInput.png"
            alt="username"
          />
        </div>
        <div className="relative">
          <input
            value={info.password}
            onChange={changeInfo}
            onBlur={removeError}
            name="password"
            type="password"
            placeholder="Password..."
            className={`bg-white rounded-md w-full outline-none py-4 px-4 border-2 ${
              error.password ? "border-red-400 border-2" : null
            }`}
          />
          <img
            className="absolute top-[1.15rem] right-3 w-[20px]"
            src="/calculation/static/passwordinput.png"
            alt="password"
          />
        </div>
      </div>
      <div>
        <button
          onClick={submitForm}
          disabled={loadingButton}
          className="py-4 w-full disabled:bg-[#2e58ffaa] bg-[#2E58FF] text-white font-bold rounded-md text-[20px] mb-10"
        >
          Login
        </button>
        <p className="mb-3">
          Don't have an account ?{" "}
          <Link to={"/singup"} className="text-blue-600">
            sign up
          </Link>
        </p>
        <p>Forgot password</p>
      </div>
      </form>

    </Fragment>
  );
};
export default LoginForm;
