import CardWhite from "../../components/Layout/common/CardWhite";
import CeilingForm from "./components/CeilingForm";
const Ceiling = ({ spaceID , place , spaceInfo , handleMainSpaceValue , addNewSpace}) => {
  return (
    <CardWhite boxBlueFull className={"box-adding"}>
      <section className="flex flex-wrap h-full">
        <CeilingForm spaceID={spaceID} addNewSpace={addNewSpace} place={place} spaceInfo={spaceInfo} handleMainSpaceValue={handleMainSpaceValue} />
      </section>
    </CardWhite>
  );
};
export default Ceiling