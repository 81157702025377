import { useState } from "react";
import { Link } from "react-router-dom";
import CardBlue from "../../../components/Layout/common/CardBlue";

const FormWall = ({ spaceID , place , createWallWindowItem }) => {
  const [wall, setWall] = useState({
    itemModel: "",
    direction: "",
    height: "",
    width: "",
  });
  const handleList = (e) => {
    setWall({
      ...wall,
      [e.target.name]: e.target.value,
    });
  };
  const postForm = async (data) => {
    const res = await createWallWindowItem(data, wall.itemModel);
    if (res == 200) {
      setWall({
        itemModel: "",
        direction: "",
        height: "",
        width: "",
      });
    }
  };
  const submitList = () => {
    let isValid = true;
    if (wall.itemModel == "") {
      isValid = false;
    }
    if (wall.dimensions == "") {
      isValid = false;
    }
    if (wall.height == "") {
      isValid = false;
    }
    if (wall.width == "") {
      isValid = false;
    }
    if (isValid) {
      postForm({
        space: spaceID,
        width: wall.width,
        height: wall.height,
        geographical_direction: wall.direction,
      });
    }
  };
  return (
    <div className="flex flex-col justify-between">
      <CardBlue className="text-center order-2 lg:order-none">
        <p className="text-2xl mb-10">Add Windows / Wall </p>
        <select
          name="itemModel"
          id="countries"
          onChange={handleList}
          value={wall.itemModel}
          className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
        >
          <option value={""}>
            Select Windows / Wall 
          </option>
          <option value="1">Window</option>
          <option value="2">Wall</option>
        </select>
        <select
          name="direction"
          id="countries"
          onChange={handleList}
          value={wall.direction}
          className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
        >
          <option value={""}>
            Geographical Direction
          </option>
          <option value="N">North</option>
          <option value="E">East</option>
          <option value="W">West</option>
          <option value="S">South</option>
        </select>
        <input
          type="width"
          onChange={handleList}
          value={wall.width}
          name="width"
          placeholder="Width ..."
          className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
        />
        <input
          name="height"
          id="height"
          onChange={handleList}
          value={wall.height}
          placeholder="Height ..."
          className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
        />
        <button
          onClick={submitList}
          className="bg-[#3EE0AF] font-medium text-white rounded-md py-3 px-20"
        >
          Add
        </button>
      </CardBlue>
      <div className="flex text-center w-full mb-10 lg:mb-0 lg:mt-5">
      <Link
        to={
          spaceID
            ? `/space-add/${place}/equipment/?space=${spaceID}`
            : `/space-add/${place}/equipment`
        }
        className="font-medium text-center bg-white text-[#3E90E0] border border-[#3E90E0] border-2 text-[18px] rounded-md w-full lg:w-[200px] py-3 mr-5"
      >
        Previous Step
      </Link>
      <Link
        to={`/BTH-space/${spaceID}`}
        className="font-medium bg-[#3E90E0] text-center text-white text-[18px] rounded-md w-full lg:w-[200px] py-3 outline-none disabled:opacity-60"
      >
        Done
      </Link>
      </div>
    </div>
  );
};
export default FormWall;
