import { useEffect, useState } from "react";
import AlertToast from "../../../components/Layout/common/AlertToast";
import BaseModal from "../../../components/Layout/common/BaseModal";

const ChnagePlaceModal = ({ changePlaceInfo , placeID ,  plcaeListItem , ToggleModal }) => {
  const [showError , setShowError] = useState(false)
  const [modalValue , setModalValue] = useState("")
  
  const ToggleToast = () => {
    setShowError(false);
  };

  const changePlace = (e) => {
    setModalValue(e.target.value)
  }

  const changeLocation = () => {
    changePlaceInfo(modalValue);
    localStorage.setItem("plcaeId", modalValue);
    ToggleModal();
  };

  useEffect(() => {
    if(placeID){
      setModalValue(placeID)
    }
  } , [placeID])
  return (
    <>
    <BaseModal ToggleModal={ToggleModal}>
        <p className="font-normal text-2xl mb-6">Your Place</p>
        <select
          name="location"
          value={modalValue}
          onChange={changePlace}
          className="bg-white text-[#707070] outline-none rounded-sm block w-full py-3 px-5 mb-6"
        >
          {plcaeListItem.map(item => <option value={item.id}>{item.title}</option>)}
        </select>
        <button
          onClick={changeLocation}
          className="bg-[#3EE0AF] font-medium text-white rounded-md py-3 px-20"
        >
          apply
        </button>
    </BaseModal>
    {showError && <AlertToast ToggleToast={ToggleToast}/>}
    </>
  );
};
export default ChnagePlaceModal;
