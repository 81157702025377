import { Fragment, useEffect, useState } from "react";
import CardBlue from "../../../components/Layout/common/CardBlue";
import CeilingFormButtons from "./CeilingFormButtons";

const CeilingForm = ({spaceID , handleMainSpaceValue , spaceInfo , place , addNewSpace}) => {
  const [ceiling, setCeiling] = useState({
    roof_width: "",
    roof_height: "",
    roof_slope_percentage: "",
  });

  useEffect(() => {
    setCeiling({
        roof_width: spaceInfo.roof_width,
        roof_height: spaceInfo.roof_height,
        roof_slope_percentage: spaceInfo.roof_slope_percentage,
    })
  }, [place])

  const handleList = (e) => {
    setCeiling({
      ...ceiling,
      [e.target.name]: e.target.value,
    });
  };
  const submitList = () => {
    let isValid = true;
    if (ceiling.roof_width == "") {
      isValid = false;
    }
    if (ceiling.roof_height == "") {
      isValid = false;
    }
    if (ceiling.roof_slope_percentage == "") {
      isValid = false;
    }
    if (isValid) {
      handleMainSpaceValue(ceiling , true);
    }
  };
  
  return (
    <Fragment>
      <CardBlue className={"h-full w-full lg:flex-1 order-2 lg:order-none"}>
        <p className="text-2xl mb-10">Ceiling Information</p>
        <div className="w-9/12 mx-auto mb-10">
          <input
            type="text"
            placeholder="Roof Width ..."
            name="roof_width"
            onChange={handleList}
            value={ceiling.roof_width}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
          <input
            type="text"
            placeholder="Roof Height ..."
            name="roof_height"
            onChange={handleList}
            value={ceiling.roof_height}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
          <input
            type="text"
            placeholder="Sroof Slope Percentage q2 ..."
            name="roof_slope_percentage"
            onChange={handleList}
            value={ceiling.roof_slope_percentage}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
        </div>
      </CardBlue>
      <CeilingFormButtons spaceID={spaceID} place={place} submitList={submitList} />
    </Fragment>
  );
};
export default CeilingForm;
