import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { config } from "../config";
import axios from "axios";

export const baseUrl = config.baseUrl;

class Api {
  constructor() {
    axios.defaults.baseURL = baseUrl;

    axios.defaults.headers.post["Content-Type"] = "application/json";
  }

  static apiInfo({ name }) {
    switch (name) {
      case "getToken":
        return {
          method: "POST",
          url: `/jwt/token/`,
          hasAuth: false,
        };
      case "register":
        return {
          method: "POST",
          url: `/account/register/`,
          hasAuth: false,
        };
      case "captcha":
        return {
          method: "POST",
          url: `/captcha/`,
          hasAuth: false,
        };

      case "refresh":
        return {
          method: "POST",
          url: `/jwt/token/refresh/`,
          hasAuth: true,
        };
      case "placeList":
        return {
          method: "GET",
          url: "/hvac/place/",
          hasAuth: true,
        };
      case "addPlace":
        return {
          method: "PUT",
          url: "/hvac/place/{param}",
          hasAuth: true,
        };
      case "createSpace":
        return {
          method: "PUT",
          url: "/hvac/space/{param}",
          hasAuth: true,
        };
      case "listSpace":
        return {
          method: "GET",
          url: "/hvac/space/{param}",
          hasAuth: true,
        };
      case "wall":
        return {
          method: "PUT",
          url: "/hvac/space/wall",
          hasAuth: true,
        };
      case "windows":
        return {
          method: "PUT",
          url: "/hvac/space/windows",
          hasAuth: true,
        };
      case "listWall":
        return {
          method: "GET",
          url: "/hvac/space/wall",
          hasAuth: true,
        };
      case "listWindows":
        return {
          method: "GET",
          url: "/hvac/space/windows",
          hasAuth: true,
        };
      case "equipmentSystemType":
        return {
          method: "GET",
          url: "/hvac/equipment-system-type/",
          hasAuth: true,
        };
        case "placeTypeList":
          return {
            method: "GET",
            url: "/hvac/place-type/",
            hasAuth: true,
          };
      case "listEquipment":
        return {
          method: "GET",
          url: "/hvac/space/equpment",
          hasAuth: true,
        };
      case "deletedEquipmentItem":
        return {
          method: "DELETE",
          url: "/hvac/space/equpment{param}",
          hasAuth: true,
        };
      case "deletedWallItem":
        return {
          method: "DELETE",
          url: "/hvac/space/wall{param}",
          hasAuth: true,
        };
      case "deletedWindowsItem":
        return {
          method: "DELETE",
          url: "/hvac/space/windows{param}",
          hasAuth: true,
        };
      case "equipmentCreate":
        return {
          method: "POST",
          url: "/hvac/space/equpment",
          hasAuth: true,
        };
      case "chartInfo":
        return {
          method: "GET",
          url: "/statistics/{param}",
          hasAuth: true,
        };
      case "getUserInfo":
        return {
          method: "GET",
          url: "/account/user-update/",
          hasAuth: true,
        };
      case "changeUserInfo":
        return {
          method: "PUT",
          url: "/account/user-update/{param}",
          hasAuth: true,
        };
      case "weatherInfo":
        return {
          method: "GET",
          url: "/account/weather/",
          hasAuth: true,
        };
        case "powerSpace":
          return {
            method: "POST",
            url: "/hvac/space/power{param}",
            hasAuth: true,
          };
      default:
        throw new Error("Bad api name");
    }
  }

  async call({ name, params, data, headers, queryParam, responseType, excel }) {
    return await this.request(
      name,
      data,
      params,
      headers,
      queryParam,
      responseType,
      excel
    );
  }

  async request(
    name,
    data = {},
    params = {},
    headers = {},
    queryParam = "",
    responseType = "json",
    excel = false
  ) {
    // Get api info
    const apiInfo = Api.apiInfo({ name });

    // Default headers
    const defaultHeaders = {
      "Content-Type": "application/json",
    };

    if (apiInfo.hasAuth) {
      const refresh = config.refreshToken();
      const exptoken = config.ExpireToken();
      if (refresh && Date.now() >= exptoken * 1000) {
        await axios("/jwt/token/refresh/", {
          method: "POST",
          data: { refresh },
        })
          .then((res) => res.data)
          .then((data) => {
            localStorage.setItem("token", data.access);
            localStorage.setItem("ExpToken", jwtDecode(data.access).exp);
          })
          .catch((e) => {
            if (e.response.status === 401) {
              localStorage.clear();
              window.location.reload();
            }
          });
      }
      const token = config.token();
      defaultHeaders["authorization"] = `Bearer ${token}`;
    }
    let url = apiInfo.url.replace("{param}", queryParam);
    let response;
    try {
      response = await axios({
        url: url,
        method: apiInfo.method,
        params: {
          ...params,
          ...apiInfo.params,
        },
        data: {
          ...data,
          ...apiInfo.data,
        },
        responseType: responseType,
        timeout: 30000,
        headers: {
          ...defaultHeaders,
          ...apiInfo.headers,
          ...headers,
        },
      });
      if (response.data.msg) {
        toast.success(response.data.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (response.data.error && Object.keys(response.data.error).length) {
        toast.success(response.data.error[0].detail, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (response.data.warning) {
        toast.warning(response.data.warning, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      toast.error(e.response.data.detail, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return e;
    }

    return response;
  }
}
export default new Api();
