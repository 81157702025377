import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../utils/api";

const SingupForm = () => {
  const history = useHistory();

  const [singinInfo, setSinginInfo] = useState({
    phone: "",
    last_name: "",
    first_name: "",
    email: "",
    password: "",
    password2: "",
  });
  const [error, setError] = useState({
    phone: false,
    last_name: false,
    first_name: false,
    email: false,
    password: false,
    password2: false,
  });
  const [errorText, setErrorText] = useState({
    phone: "",
    last_name: "",
    first_name: "",
    email: "",
    password: "",
    password2: "",
  });
  const handleValue = (e) => {
    setSinginInfo((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const validation = (e) => {
    let isValid = true;
    let errorValid = {
      phone: false,
      last_name: false,
      first_name: false,
      email: false,
      password: false,
      password2: false,
    };
    if (singinInfo.first_name.trim() === "") {
      isValid = false;
      errorValid["first_name"] = true;
    }
    if (singinInfo.last_name.trim() === "") {
      isValid = false;
      errorValid["last_name"] = true;
    }
    if (singinInfo.email.trim() === "" || !singinInfo.email.includes("@")) {
      isValid = false;
      errorValid["email"] = true;
    }
    if (singinInfo.password.trim() === "") {
      isValid = false;
      errorValid["password"] = true;
    }
    if (
      singinInfo.password2.trim() === "" ||
      singinInfo.password2 !== singinInfo.password
    ) {
      isValid = false;
      errorValid["password2"] = true;
    }
    setError(errorValid);
    return isValid;
  };
  const submitForm = async (e) => {
    setErrorText({
      phone: "",
      last_name: "",
      first_name: "",
      email: "",
      password: "",
      password2: "",
    });
    if (validation(e)) {
      let data = singinInfo;
      if (singinInfo.phone === "") {
        delete data.phone;
      }
      const register = await api.call({
        name: "register",
        data,
      });
      if(register.status !== 201){
        if (
          register.response.status === 400 &&
          Object.keys(register.response.data).length
        ) {
          setErrorText((prev) => {
            return {
              ...prev,
              ...register.response.data,
            };
          });
        }
      }
      if (register.status === 201) {
        toast.success("User Created Please Login", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        history.push("/login");
      }
    }
  };
  return (
    <>
      <div className="mb-14">
        <div className="mb-7 relative">
          <input
            type="text"
            name={"first_name"}
            onChange={handleValue}
            placeholder="First Name..."
            className={`border bg-white rounded-md w-full outline-none py-4 px-4 ${
              error.first_name ? "border-red-500" : ""
            }`}
          />
          <img
            className="absolute top-[1.15rem] right-3 w-[20px]"
            src="/calculation/static/usernameInput.png"
            alt="username"
          />
          {errorText.first_name ? (
            <p className="text-start mb-7 text-red-500">
              {errorText.first_name}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="mb-7 relative">
          <input
            type="text"
            name={"last_name"}
            onChange={handleValue}
            placeholder="Last Name..."
            className={`border bg-white rounded-md w-full outline-none py-4 px-4 ${
              error.last_name ? "border-red-500" : ""
            }`}
          />
          <img
            className="absolute top-[1.15rem] right-3 w-[20px]"
            src="/calculation/static/usernameInput.png"
            alt="username"
          />
          {errorText.last_name ? (
            <p className="text-start mb-7 text-red-500">
              {errorText.last_name}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="mb-7 relative">
          <input
            type="text"
            name={"email"}
            onChange={handleValue}
            placeholder="Email ..."
            className={`border bg-white rounded-md w-full outline-none py-4 px-4 ${
              error.email ? "border-red-500" : ""
            }`}
          />
          <img
            className="absolute top-[1.15rem] right-3 w-[20px]"
            src="/calculation/static/usernameInput.png"
            alt="username"
          />
          {errorText.email ? (
            <p className="text-start mb-7 text-red-500">{errorText.email}</p>
          ) : (
            ""
          )}
        </div>
        <div className="mb-7 relative">
          <input
            type="text"
            name={"phone"}
            onChange={handleValue}
            placeholder="Phone number..."
            className={`border bg-white rounded-md w-full outline-none py-4 px-4 ${
              error.phone ? "border-red-500" : ""
            }`}
          />
          <img
            className="absolute top-[1.15rem] right-3 w-[20px]"
            src="/calculation/static/usernameInput.png"
            alt="username"
          />
          {errorText.phone ? (
            <p className="text-start mb-7 text-red-500">{errorText.phone}</p>
          ) : (
            ""
          )}
        </div>
        <div className="mb-7 relative">
          <input
            type="password"
            placeholder="Password..."
            name={"password"}
            onChange={handleValue}
            className={`border bg-white rounded-md w-full outline-none py-4 px-4 ${
              error.password ? "border-red-500" : ""
            }`}
          />
          <img
            className="absolute top-[1.15rem] right-3 w-[20px]"
            src="/calculation/static/passwordinput.png"
            alt="password"
          />
          {errorText.password ? (
            <p className="text-start mb-7 text-red-500">{errorText.password}</p>
          ) : (
            ""
          )}
        </div>
        <div className="relative">
          <input
            type="password"
            placeholder="Again Password..."
            name={"password2"}
            onChange={handleValue}
            className={`border bg-white rounded-md w-full outline-none py-4 px-4 ${
              error.password2 ? "border-red-500" : ""
            }`}
          />
          <img
            className="absolute top-[1.15rem] right-3 w-[20px]"
            src="/calculation/static/passwordinput.png"
            alt="password"
          />
          {errorText.password2 ? (
            <p className="text-start mb-7 text-red-500">
              {errorText.password2}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <button
          onClick={submitForm}
          className="py-4 w-full bg-[#2E58FF] text-white font-bold rounded-md text-[20px] mb-10"
        >
          Singup
        </button>
        <p className="mb-3">
          Do you have an account ?{" "}
          <Link to={"/login"} className="text-blue-600">
            sign in
          </Link>
        </p>
        <p>Forgot password</p>
      </div>
    </>
  );
};
export default SingupForm;
