import Sidebar from "./Sidebar"
import { AiOutlineMenu } from "react-icons/ai";
import "./style.scss"
import { useState } from "react";
import { Link } from "react-router-dom";
const Layout = ({children , addNewItem}) => {
    const [showMenuMobile , setShowMenuMobile] = useState(false)
    const closeSlider = () => setShowMenuMobile(false)
    return(
        <>
        <section className="py-10">
            <nav className="bg-white fixed top-0 w-full flex justify-between items-center px-10 py-3 z-50 xl:hidden shadow-lg">
                <Link to={"/"}>
                    <img className="w-[50px]" src="/calculation/static/logo.png"/>
                </Link>
                <AiOutlineMenu className="cursor-pointer" onClick={() => setShowMenuMobile((prev) => !prev)} size="1.5rem"/>
            </nav>
            <section className="container px-4 mx-auto">
            <div className="flex">
                <aside className={`h-sidebar z-30 bg-white w-[356px] px-10 py-5 block ${showMenuMobile ? "showMenu" : ""}`}>
                    <Sidebar addNewItem={addNewItem} closeSlider={closeSlider}/>
                </aside> 
                <main className="mt-24 xl:mt-0 xl:ml-10 main">
                    {children}
                </main> 
            </div>
            </section>
        </section>
        <div onClick={() => setShowMenuMobile((prev) => !prev)} className={`${showMenuMobile ? "show" : "hidden"} xl:hidden bg-slider-black`}></div>
        </>
    )
}

export default Layout