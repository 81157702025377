import api from "../../../utils/api"

const EquipmentItem = ({getListItme, item}) => {
    const deletedItem = async (id = "") => {
        const equipmentItemDeleted = await api.call({
            name : "deletedEquipmentItem",
            queryParam : `?id=${id}`
        })
        getListItme()
    }
    return(
        <div className="bg-white text-left py-3 px-4 mb-4 rounded-lg relative">
            <p className="mb-3 text-lg">
                {item.title}
            </p>
            <ul className="ml-5">
                <li className="mb-1 text-sm">Type : {item.type === "p" ? "People" : "Light"}</li>
                {item.KW_per_ft2 ? <li className="mb-1 text-sm">KW_per_ft2 : {item.KW_per_ft2}</li> :  null}
                {item.number_of_person ? <li className="mb-1 text-sm">number_of_person : {item.number_of_person}</li> : null}
            </ul>
            <button onClick={() => deletedItem(item.id)} className="absolute right-3 bottom-3  text-[13px] text-red-500">Delete</button>
        </div>
    )
}
export default EquipmentItem