import { useState } from "react";
import CardWhite from "../../components/Layout/common/CardWhite";
import FloorForm from "./components/FloorForm";

const Floor = ({spaceInfo , handleMainSpaceValue , place , spaceID}) => {
  return (
    <CardWhite boxBlueFull className={"box-adding"}>
      <section className="flex flex-wrap h-full">
        <FloorForm spaceID={spaceID} place={place} spaceInfo={spaceInfo} handleMainSpaceValue={ handleMainSpaceValue } />
      </section>
    </CardWhite>
  );
};
export default Floor;
