import { useEffect, useState } from "react";
import api from "../../../utils/api";
import WallItem from "./WallItem";

const ListItem = ({spaceID , getListItme , itemListData}) => {
  return (
    <>
      {itemListData.length ? itemListData.map((item , index) => {
        if(item.space == spaceID){
          return <WallItem key={item.customId} getListItme={getListItme} item={item} />;
        }
      }) : null}
    </>
  );
};
export default ListItem;
