import { Link } from "react-router-dom"

const SpaceFormButtons = ({spaceID ,  submitList , place}) => {
    return(
        <div className="flex lg:flex-col w-full lg:w-auto items-center justify-between lg:justify-center lg:pl-24 mb-10 lg:mb-0">
        <Link onClick={submitList}  to={spaceID ? `/space-add/${place}/floor/?space=${spaceID}` : `/space-add/${place}/floor`} className="font-medium bg-[#3E90E0] text-center text-white text-[18px] rounded-md w-full lg:w-[200px] py-3 outline-none lg:mb-5 mr-5 lg:mr-0 disabled:opacity-60">
          Next Step
        </Link>
        <Link
          to="/"
          className="font-medium text-center bg-white text-[#3E90E0] border border-[#3E90E0] border-2 text-[18px] rounded-md w-full lg:w-[200px] py-3"
        >
          Cancel
        </Link>
      </div>
    )
}
export default SpaceFormButtons