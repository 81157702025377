import { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import CardWhite from "../../components/Layout/common/CardWhite"
import ModalWeatherInfo from "../../components/Layout/common/ModalWeatherInfo"
import api from "../../utils/api"

const PlaceList = () => {
    const [weatherInfoItem , setWeatherInfoItem] = useState({})
    const [showModal , setShowModal] = useState(false)
    const [showContent , setShowContent] = useState(false)
    const getProfileID = async () => {
        const userInfo = await api.call({
          name: "getUserInfo",
        });
        if(userInfo.data && userInfo.data.length){
            if(userInfo.data[0].reigion && userInfo.data[0].location && userInfo.data[0].city){
                setWeatherInfoItem({
                    reigion : userInfo.data[0].reigion ,
                    location : userInfo.data[0].location ,
                    city : userInfo.data[0].city
                })
            }
        }
    }
    const history = useHistory()

    const ToggleModal = () => {
        setShowModal((prev) => {
            return !prev
        })
    }
    const AddWeatherInfo = (value) => {
        setWeatherInfoItem(value)
    }
    const getplaceList = async () => {
        const place =  await api.call({
            name : "placeList"
        })
        if(place.data && !place.data.length){
            setShowContent(true)
        }else{
            history.push("/")
        }
    }
    useEffect(() => {
        getplaceList()
        getProfileID()
    } , [])
    return(
        <>
        {showContent ? <CardWhite bigBox HeightFull>
            <div>
                <p className="text-2xl font-medium">
                Dashboard
                </p>
            </div>
            <div className="my-44 flex flex-col justify-center items-center">
                <img className="w-[80px] mb-4" src="/calculation/static/emptyBox.png"/>
                <p className="text-[18px] font-medium mb-2">
                No data
                </p>
                <p className="mb-12 text-center">
                {Object.keys(weatherInfoItem).length  == 0 ? "This is first time you are using HVAC system , Complete the weather information to get started " : "Pleas add your first place"}
                </p>
                <div className="flex flex-wrap w-full md:w-auto">
                    <button onClick={ToggleModal} className="font-medium mb-4 md:mb-0 bg-[#FFB95C] text-white text-[18px] rounded-md w-full md:w-[200px] py-3 mx-3">
                        {!Object.keys(weatherInfoItem).length ? "Weather Info" : "Edit Weather Info"}
                    </button>
                    {Object.keys(weatherInfoItem).length ? <Link to="/place-add" className="font-medium mb-4 md:mb-0 bg-[#3EE0AF] text-center text-white text-[18px] rounded-md w-full md:w-[200px] py-3 mx-3 disabled:opacity-60">
                    Add Place
                    </Link> : <button disabled className="font-medium mb-4 md:mb-0 bg-[#3EE0AF] text-center text-white text-[18px] rounded-md w-full md:w-[200px] py-3 mx-3 disabled:opacity-60">
                    Add Place
                    </button>}
                </div>
            </div>
        </CardWhite> : null}
        {showModal && <ModalWeatherInfo weatherInfoItem={weatherInfoItem} AddWeatherInfo={AddWeatherInfo} ToggleModal={ToggleModal}/>}
        </>
    )
}
export default PlaceList