import { useState } from "react";

const CustomPopover = ({ text="" , title="" }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <p className="text-center">
        {title}
      </p>
      {isHovering && (
        <div className="absolute right-0 z-10 mt-2 text-[14px] w-56 text-center rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {text}
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomPopover
