import { Redirect, Route } from "react-router-dom";
import { config } from "../../utils/config";
const ChekcAuthRouteSpace = (props) => {
  return (
    <>
      <Route>
        {() => {
          const tokenLocal = config.token();
          if (tokenLocal) {
            if(props.validation){
                return <Route {...props} />;
            }else{
                return <Redirect to={props.lastPage}/>;
            }
          } else {
            return <Redirect to="/login" />;
          }
        }}
      </Route>
    </>
  );
};
export default ChekcAuthRouteSpace;
