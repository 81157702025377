import { useEffect, useState } from "react";
const BaseModal = ({ children, ToggleModal }) => {
  return (
    <>
      <div
        onClick={ToggleModal}
        className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-40 z-[1000]"
      ></div>
      <div className="w-[350px] sm:w-[500px] bg-[#F2F8FC] rounded-md py-8 px-14 text-center fixed top-40 left-0 right-0 mx-auto z-[1001]">
        {children}
      </div>
    </>
  );
};
export default BaseModal;
