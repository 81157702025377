import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CardWhite from "../../components/Layout/common/CardWhite";
import api from "../../utils/api";
import AirQuality from "./components/AirQuality";
import { EnergyMeter } from "./components/EnergyMeter";
import PlaceItem from "./components/PlaceItem";
import RelativeHumidity from "./components/RelativeHumidity";
import Temperature from "./components/Temperature";
import "./style.scss";
const Dashbord = () => {
  const [plcaeInfo , setPlaceInfo] = useState({})
  const [plcaeListItem , setPlaceListItem] = useState("")
  const [placeID , setPlaceID] = useState("")
  const [chartInfo , setChartData] = useState("")
  const [showContent , setShowContent] = useState(false)
  
  const history = useHistory()

  const getPlaceList = async () => {
    const placeList = await api.call({
      name: "placeList",
    });
    if(placeList.data && placeList.data.length){
      setShowContent(true)
    }else{
        history.push("/place-list")
    }
    if(placeList.data.length){
      setPlaceListItem(placeList.data)
      let placeIdLocal = localStorage.getItem("plcaeId")
        if(placeIdLocal){
        let findPlaceById = placeList.data.find(item => {
          return item.id == placeIdLocal
        })
        setPlaceInfo(findPlaceById)
        setPlaceID(findPlaceById.id)
      }else if(!placeIdLocal){
        localStorage.setItem("plcaeId" , placeList.data[placeList.data.length - 1].id)
        setPlaceInfo(placeList.data[placeList.data.length - 1])
        setPlaceID(placeList.data[placeList.data.length - 1].id)
      }
    }
  };

  const getChartInfo =  async () => {
    let chartDatas = await api.call({
      name : "chartInfo",
      queryParam : `/data-daily/?place=${placeID}`
    })
    setChartData(chartDatas.data);
  }

  const changePlaceInfo = (id) => {
    setPlaceID(id)
    let findPlace = plcaeListItem.find(item => {
      return item.id == id
    })
    setPlaceInfo(findPlace);
  }

  useEffect(() => {
    getPlaceList()
  }, []);

  useEffect(() => {
    if(placeID){
      getChartInfo()
    }
  } , [placeID])
  return (
    <>
    {showContent ? <div className="flex flex-col h-full">
      <div className="w-full mb-4">
        <PlaceItem changePlaceInfo={changePlaceInfo} plcaeListItem={plcaeListItem} placeID={placeID} plcaeInfo={plcaeInfo}/>
      </div>
      <div className="flex flex-wrap w-full h-dashbord">
        <div className="px-1 w-full lg:w-9/12 mb-4">
          <CardWhite HeightFull>
            <AirQuality placeID={placeID}/>
          </CardWhite>
        </div>
        <div className="px-1 w-full sm:w-6/12 lg:w-3/12 order-2 lg:order-none mb-4 sm:mb-0 lg:mb-4 lg:pl-4">
          {chartInfo ? <Temperature Temperature={chartInfo.Temperature}/> : null}
        </div>
        <div className="px-1 w-full mb-4 lg:mb-0 lg:w-9/12">
          <CardWhite HeightFull>
            <EnergyMeter placeID={placeID}/>
          </CardWhite>
        </div>
        <div className="px-1 w-full sm:w-6/12 lg:w-3/12 order-2 lg:order-none lg:pl-4">
          {chartInfo ? <RelativeHumidity RelativeHumidity={chartInfo['Relative Humidity']}/> : null}
        </div>
      </div>
    </div> : true}
    </>
  );
};
export default Dashbord;
