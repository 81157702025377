import { Link } from "react-router-dom";

const EquipmentFormButtons = ({ submitList, place, spaceID }) => {
  return (
    <div className="flex text-center w-full mb-10 lg:mb-0 lg:mt-5 items-center">
      <Link
        to={
          spaceID
            ? `/space-add/${place}/ceiling/?space=${spaceID}`
            : `/space-add/${place}/ceiling`
        }
        className="font-medium text-center bg-white text-[#3E90E0] border border-[#3E90E0] border-2 text-[18px] rounded-md w-full lg:w-[200px] py-3 mr-5"
      >
        Previous Step
      </Link>
      <Link
        to={
          spaceID
            ? `/space-add/${place}/wall/?space=${spaceID}`
            : `/space-add/${place}/wall`
        }
        className="font-medium bg-[#3E90E0] text-center text-white text-[18px] rounded-md w-full lg:w-[200px] py-3 outline-none disabled:opacity-60"
      >
        Next Step
      </Link>
    </div>
  );
};
export default EquipmentFormButtons;
