import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import CardBlue from "../../../../components/Layout/common/CardBlue";
import api from "../../../../utils/api";
const PlaceEditForm = () => {
  const { params } = useRouteMatch();
  const [equipmentAndSystemList, setEquipmentAndSystemList] = useState("");
  const [equipmentList, setEquipmentList] = useState("");
  const [placeTypeList, setPlaceTypeList] = useState("");
  const [systemList, setSystemList] = useState("");
  const [equipmentId, setEquipmentId] = useState("");
  const [initialValuesData, setInitialValues] = useState("");
  const history = useHistory();
  const [showData, setShowDate] = useState(false);

  const getEquipmentSystemType = async () => {
    const list = await api.call({
      name: "equipmentSystemType",
    });
    if (list.data.length) {
      setEquipmentList(
        list.data.map((item) => {
          return { id: item.id, title: item.title };
        })
      );
    }
    setEquipmentAndSystemList(list.data);
  };

  const getPlaceItem = async () => {
    const placeList = await api.call({
      name: "placeList",
    });
    if(placeList.data.length === 0){
      history.push("/")
    }
    setShowDate(true);
    if (placeList.data.length) {
      let thisPlace = placeList.data.find((item) => {
        return item.id == params.id;
      });
      setEquipmentId(thisPlace.equipment);
      setInitialValues({
        title: thisPlace.title,
        equipment: thisPlace.equipment,
        system: thisPlace.system,
        place_type : thisPlace.place_type
      });
    }
  };
  const getPlaceTypeList = async () => {
    const list = await api.call({
      name: "placeTypeList",
    });
    setPlaceTypeList(list.data);
  }
  const getAlldata = async () => {
    await getEquipmentSystemType();
    await getPlaceItem();
    await getPlaceTypeList()
  };
  useEffect(() => {
    getAlldata();
  }, []);

  useEffect(() => {
    if (equipmentAndSystemList.length) {
      let equipmentSelected = equipmentAndSystemList.find((item) => {
        return item.id == equipmentId;
      });
      if (equipmentSelected) {
        setSystemList(equipmentSelected.system);
      }
    }
  }, [equipmentId]);

  return (
    <>
      {showData ? (
        <Formik
          enableReinitialize
          initialValues={initialValuesData}
          validate={(values) => {
            const errors = {};
            if (!values.title) {
              errors.title = "required";
            }
            if (!values.equipment) {
              errors.equipment = "required";
            }
            if (!values.system) {
              errors.system = "required";
            }
            if (!values.place_type) {
              errors.place_type = "required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const addPlace = await api.call({
              name: "addPlace",
              queryParam: `?id=${params.id}`,
              data: values,
            });
            if (addPlace.status === 201 || addPlace.status === 200) {
              history.push("/");
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form
              className="mt-10 lg:mt-24 flex flex-wrap"
              onSubmit={handleSubmit}
            >
              <>
                <CardBlue className={"lg:flex-1 w-full lg:w-auto"}>
                  <p className="text-2xl mb-10">Place Information</p>
                  <div className="w-9/12 mx-auto">
                    <input
                      type="text"
                      placeholder="Place name ..."
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      className={`bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 ${
                        errors.title ? "border-red-500" : "mb-6"
                      }`}
                    />
                    <div className="text-red-500 text-start mb-6">
                      {errors.title && touched.title && errors.title}
                    </div>
                    <select
                    name="place_type"
                    id="place_type"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={values.place_type}
                    className={`bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 ${
                      errors.place_type ? "border-red-500" : "mb-6"
                    }`}
                  >
                    <option value="">
                      place type
                    </option>
                    {placeTypeList
                      ? placeTypeList.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        ))
                      : null}
                  </select>
                  <div className="text-red-500 text-start mb-6">
                    {errors.place_type && touched.place_type && errors.place_type}
                  </div>
                    <select
                      name="equipment"
                      id="countries"
                      onChange={(e) => {
                        handleChange(e);
                        setEquipmentId(e.target.value);
                        setFieldValue("system", "");
                      }}
                      onBlur={handleBlur}
                      defaultValue={values.equipment}
                      className={`bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 ${
                        errors.equipment ? "border-red-500" : "mb-6"
                      }`}
                    >
                      <option value="">Equipment type</option>
                      {equipmentList
                        ? equipmentList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))
                        : null}
                    </select>
                    <div className="text-red-500 text-start mb-6">
                      {errors.equipment &&
                        touched.equipment &&
                        errors.equipment}
                    </div>
                    <select
                      name="system"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.system}
                      disabled={!values.equipment}
                      className={`bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 ${
                        errors.system ? "border-red-500" : "mb-6"
                      }`}
                    >
                      <option value="">System Type</option>
                      {systemList
                        ? systemList.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ))
                        : null}
                    </select>
                    <div className="text-red-500 text-start mb-6">
                      {errors.system && touched.system && errors.system}
                    </div>
                  </div>
                </CardBlue>
                <div className="flex w-full lg:w-auto flex-col items-center justify-center mt-10 lg:mt-0 lg:pl-24">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`font-medium bg-[#3EE0AF] text-center text-white text-[18px] rounded-md w-[200px] py-3 outline-none mb-5 disabled:opacity-60`}
                  >
                    Edit Place
                  </button>
                  <Link
                    to="/"
                    className="font-medium text-center bg-white text-[#3E90E0] border border-[#3E90E0] border-2 text-[18px] rounded-md w-[200px] py-3"
                  >
                    Cancel
                  </Link>
                </div>
              </>
            </form>
          )}
        </Formik>
      ) : null}
    </>
  );
};
export default PlaceEditForm;
