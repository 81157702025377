import { useState } from "react";
import CardWhite from "../../components/Layout/common/CardWhite";
import ModalSpace from "./components/SpaceModal";
import SpaceForm from "./components/SpaceForm";

const Space = ({handleMainSpaceValue , spaceInfo , place , spaceID}) => {
  const [showModal, setShowModal] = useState(false);
  const ToggleModal = () => {
    setShowModal((prev) => {
      return !prev;
    });
  };
  return (
    <CardWhite boxBlueFull className={"box-adding"}>
      <section className="flex flex-wrap h-full">
        <SpaceForm spaceID={spaceID} place={place} spaceInfo={spaceInfo} handleMainSpaceValue={handleMainSpaceValue}/>
      </section>
      {showModal && <ModalSpace ToggleModal={ToggleModal} />}
    </CardWhite>
  );
};
export default Space;
