import { Link } from "react-router-dom"

const FloorFormButtons = ({ spaceID , submitList , place }) => {
    return(
        <div className="flex lg:flex-col w-full lg:w-auto items-center justify-between lg:justify-center lg:pl-24 mb-10 lg:mb-0">
        <Link onClick={submitList}  to={spaceID ? `/space-add/${place}/ceiling/?space=${spaceID}` : `/space-add/${place}/ceiling`} className="font-medium bg-[#3E90E0] text-center text-white text-[18px] rounded-md w-full lg:w-[200px] py-3 outline-none lg:mb-5 mr-5 lg:mr-0 disabled:opacity-60">
          Next Step
        </Link>
        <Link
          to={spaceID ? `/space-add/${place}/space/?space=${spaceID}` : `/space-add/${place}/space`}
          className="font-medium text-center bg-white text-[#3E90E0] border border-[#3E90E0] border-2 text-[18px] rounded-md w-full lg:w-[200px] py-3"
        >
          Previous Step
        </Link>
      </div>
    )
}
export default FloorFormButtons