import { useRef } from "react";
import "./style.scss";
const Temperature = ({Temperature}) => {
  return (
    <div className="small-card temp">
      <p className="text-xl bold">Temperature</p>
      <div className="flex items-center justify-center my-10">
        <img className="w-[70px] h-[70px]" src="/calculation/static/thermometer.png" />
        <p className="text-4xl font-mediums">{Temperature[Object.keys(Temperature)[Object.keys(Temperature).length - 1]].value}°C</p>
      </div>
      <div className="grid grid-cols-3 divide-x w-full">
        <div className="text-xs px-2">
        <p>{Temperature[Object.keys(Temperature)[Object.keys(Temperature).length - 2]].avg_value}°C</p>
          <p>yesterday</p>
        </div>
        <div className="text-xs px-2">
        <p>{Temperature[Object.keys(Temperature)[Object.keys(Temperature).length - 3]].avg_value}°C</p>
          <p>2 days ago</p>
        </div>
        <div className="text-xs px-2">
        <p>{Temperature[Object.keys(Temperature)[Object.keys(Temperature).length - 4]].avg_value}°C</p>
          <p>3 days ago</p>
        </div>
      </div>
    </div>
  );
};
export default Temperature;
