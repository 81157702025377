import React from "react";

const RelativeHumidity = ({RelativeHumidity}) => {
  return (
    <div className="small-card relative-hunidity">
      <p className="text-xl bold">Relative Humidity</p>
      <div className="flex items-center justify-center  my-10">
        <img className="w-[70px] h-[70px]" src="/calculation/static/humidity.png" />
        <p className="text-4xl font-mediums">{RelativeHumidity[Object.keys(RelativeHumidity)[Object.keys(RelativeHumidity).length - 1]].value} %</p>
      </div>
      <div className="grid grid-cols-3 divide-x w-full">
        <div className="text-xs px-2">
          <p>{RelativeHumidity[Object.keys(RelativeHumidity)[Object.keys(RelativeHumidity).length - 2]].avg_value}%</p>
          <p>yesterday</p>
        </div>
        <div className="text-xs px-2">
        <p>{RelativeHumidity[Object.keys(RelativeHumidity)[Object.keys(RelativeHumidity).length - 3]].avg_value}%</p>
          <p>2 days ago</p>
        </div>
        <div className="text-xs px-2">
        <p>{RelativeHumidity[Object.keys(RelativeHumidity)[Object.keys(RelativeHumidity).length - 4]].avg_value}%</p>
          <p>3 days ago</p>
        </div>
      </div>
    </div>
  )
};
export default RelativeHumidity;
