import { Link } from "react-router-dom";

const CeilingFormButtons = ({ spaceID, submitList, place }) => (
  <div className="flex lg:flex-col w-full lg:w-auto items-center justify-between lg:justify-center lg:pl-24 mb-10 lg:mb-0">
    <button
      onClick={submitList}
      className="font-medium bg-[#3E90E0] text-center text-white text-[18px] rounded-md w-full lg:w-[200px] py-3 outline-none lg:mb-5 mr-5 lg:mr-0 disabled:opacity-60"
    >
      Next Step
    </button>
    <Link
      to={spaceID
        ? `/space-add/${place}/floor/?space=${spaceID}`
        : `/space-add/${place}/floor`}
      className="font-medium text-center bg-white text-[#3E90E0] border border-[#3E90E0] border-2 text-[18px] rounded-md w-full lg:w-[200px] py-3"
    >
      Previous Step
    </Link>
  </div>
);
export default CeilingFormButtons;
