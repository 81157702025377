import { useEffect, useState } from "react";
import {
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { toast } from "react-toastify";
import ChekcAuthRoute from "../../Auth/ChekcAuthRoute";
import CardWhite from "../../components/Layout/common/CardWhite";
import api from "../../utils/api";
import { config } from "../../utils/config";
import Ceiling from "../Ceiling";
import Equipment from "../Equipment";
import Floor from "../Floor";
import Space from "../Space";
import Wall from "../Wall";
import ChekcAuthRouteSpace from "./ChekcAuthRouteSpace";
import AlearRegisterCode from "./components/AlearRegisterCode";
import SpaceStepBar from "./components/SpaceStepBar";
import "./style.scss";
const SpaceAdd = () => {
  const { params, url, path } = useRouteMatch();
  const { search } = useLocation();
  const history = useHistory();

  const [token, setToken] = useState("");
  const [spaceID, setSpaceID] = useState("");
  const [query, setQuery] = useState(search.replace("?space=", ""));
  const [spaceInfo, setSpaceInfo] = useState("");
  const [reajesterCode, setReajesterCode] = useState("");

  const GetLocalToken = () => {
    setToken(config.token());
  };

  const addNewSpace = async (value = "") => {
    let createSpace = "";
    if (spaceID) {
      createSpace = await api.call({
        name: "createSpace",
        data: value,
        queryParam: `?id=${spaceID}`,
      });
    } else {
      createSpace = await api.call({
        name: "createSpace",
        data: value,
      });
      setSpaceID(createSpace.data.id);
      setReajesterCode(createSpace.data.register_code);
    }
    history.push(`${url}/equipment/?space=${createSpace.data.id}`);
  };

  const getSpaceList = async () => {
    const spaceList = await api.call({
      name: "listSpace",
      queryParam: `?id=${query}`,
    });
    if (Object.keys(spaceList.data).length) {
      setSpaceInfo(spaceList.data);
      setSpaceID(spaceList.data.id);
      setReajesterCode(spaceList.data.register_code);
    } else {
      setSpaceInfo({
        title: "",
        place: "",
        device_serial_ID: "",
        sq2: "",
        ceiling_height: "",
        floor_type: "",
        floor_width: "",
        floor_height: "",
        roof_width: "",
        roof_height: "",
        roof_slope_percentage: "",
      });
    }
  };

  useEffect(() => {
    if (!parseInt(params.place)) {
      history.push("/");
    } else {
      setSpaceInfo((prev) => {
        return {
          ...prev,
          place: params.place,
        };
      });
    }
  }, [params]);

  useEffect(() => {
    if (query) {
      getSpaceList();
    } else {
      setSpaceInfo({
        title: "",
        place: "",
        device_serial_ID: "",
        sq2: "",
        ceiling_height: "",
        floor_type: "",
        floor_width: "",
        floor_height: "",
        roof_width: "",
        roof_height: "",
        roof_slope_percentage: "",
      });
    }
  }, [query]);

  const handleMainSpaceValue = (value, last = false) => {
    setSpaceInfo((prev) => {
      return {
        ...prev,
        ...value,
      };
    });
    if (last) {
      addNewSpace({
        ...spaceInfo,
        place: parseInt(spaceInfo.place),
        ...value,
      });
    }
  };

  useEffect(() => {
    GetLocalToken();
  }, []);

  return (
    <div className="h-full">
      <CardWhite
        className={`flex items-center ${reajesterCode ? "mb-5" : "mb-10"}`}
      >
        <SpaceStepBar query={query} placeId={params.place} />
      </CardWhite>
      {reajesterCode ? (
        <AlearRegisterCode reajesterCode={reajesterCode} />
      ) : null}
      <Switch>
        <ChekcAuthRoute
          render={() => (
            <Space
              spaceID={spaceID}
              place={params.place}
              spaceInfo={spaceInfo}
              handleMainSpaceValue={handleMainSpaceValue}
            />
          )}
          exact
          token={token}
          path={`${url}/space`}
          validation={true}
        />
        <ChekcAuthRouteSpace
          render={() => (
            <Floor
              spaceID={spaceID}
              place={params.place}
              spaceInfo={spaceInfo}
              handleMainSpaceValue={handleMainSpaceValue}
            />
          )}
          exact
          token={token}
          path={`${url}/floor`}
          lastPage={`${url}/space`}
          validation={
            spaceInfo.title &&
            spaceInfo.device_serial_ID &&
            spaceInfo.ceiling_height &&
            spaceInfo.sq2
          }
        />
        <ChekcAuthRouteSpace
          render={() => (
            <Ceiling
              spaceID={spaceID}
              addNewSpace={addNewSpace}
              place={params.place}
              spaceInfo={spaceInfo}
              handleMainSpaceValue={handleMainSpaceValue}
            />
          )}
          exact
          token={token}
          path={`${url}/ceiling`}
          lastPage={`${url}/floor`}
          validation={
            spaceInfo.floor_height &&
            spaceInfo.floor_type &&
            spaceInfo.floor_width
          }
        />
        <ChekcAuthRouteSpace
          render={() => <Equipment spaceID={spaceID} place={params.place} />}
          exact
          token={token}
          path={`${url}/equipment`}
          lastPage={`${url}/space`}
          validation={spaceID}
        />
        <ChekcAuthRouteSpace
          render={() => <Wall spaceID={spaceID} place={params.place} />}
          exact
          token={token}
          path={`${url}/wall`}
          lastPage={`${url}/space`}
          validation={spaceID}
        />
      </Switch>
    </div>
  );
};
export default SpaceAdd;
