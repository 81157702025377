import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import "../style.scss"
const SpaceStepBar = ({ placeId , query }) => {
    const [stepList  , setStepList] = useState([
        {text : "Space" , icon : "Space" , route : "space"},
        {text : "Floor" , icon : "Floor" , route : "floor"},
        {text : "Ceiling" , icon : "Ceiling" , route : "ceiling"},
        {text : "Equipment" , icon : "Equipment" , route : "equipment"}, 
        {text : "Wall,Windows" , icon : "Wall" , route : "wall"},
    ])
    const location = useLocation()
    return(
        <div className="w-full relative">
            <ul className="w-full md:w-[80%] mx-auto flex items-center justify-between line-center">
                {stepList.map(item => {
                    return(
                        <li key={item.text} className='text-center z-10'>
                            <Link className="flex flex-col items-center" to={query ? `/space-add/${placeId}/${item.route}/?space=${query}` : `/space-add/${placeId}/${item.route}`}>
                            <div className="w-[40px] sm:w-[80px] lg:w-[90px] 2xl:w-[110px] h-[40px] sm:h-[80px] lg:h-[90px] 2xl:h-[110px] flex items-center justify-center bg-[#EBF8FE] rounded-full">
                                <img className={`w-[20px] sm:w-[40px] 2xl:w-[50px] ${(location.pathname == `/space-add/${placeId}/${item.route}`) || (location.pathname == `/space-add/${placeId}/${item.route}/`) ? "" : "opacity-40"}`} src={`/calculation/static/${item.icon}.png`} alt={item.icon}/>
                            </div>
                            <p className="mt-2 text-[.5rem] sm:text-base">
                                {item.text}
                            </p>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default SpaceStepBar