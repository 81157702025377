import { Fragment, useEffect, useState } from "react";
import CardBlue from "../../../components/Layout/common/CardBlue";
import FloorFormButtons from "./FloorFormButtons";

const FloorForm = ({ spaceID , handleMainSpaceValue, spaceInfo , place }) => {
  const [floor, setFloor] = useState();

  useEffect(() => {
    if(spaceInfo){
      setFloor({
        floor_type: spaceInfo.floor_type,
        floor_width: spaceInfo.floor_width,
        floor_height: spaceInfo.floor_height,
      });
    }
  }, [spaceInfo]);

  const handleList = (e) => {
    setFloor({
      ...floor,
      [e.target.name]: e.target.value,
    });
  };
  const submitList = () => {
    let isValid = true;
    if (floor.floor_type == "") {
      isValid = false;
    }
    if (floor.floor_width == "") {
      isValid = false;
    }
    if (floor.floor_height == "") {
      isValid = false;
    }
    if (isValid) {
      handleMainSpaceValue(floor);
    }
  };

  return (
    <Fragment>
      {floor ? <CardBlue className={"h-full w-full lg:flex-1 order-2 lg:order-none"}>
        <p className="text-2xl mb-10">Floor Information</p>
        <div className="w-9/12 mx-auto mb-10">
          <select
            name="floor_type"
            onChange={handleList}
            value={floor.floor_type}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          >
            <option value={""}>
              Select Floor Type
            </option>
            <option value="1">Floor Above Conditioned Space</option>
            <option value="2">Floor Above Unconditioned Space</option>
            <option value="3">Slab Floor On Grade</option>
            <option value="4">Slab Floor Below Grade</option>
          </select>
          <input
            type="text"
            placeholder="Floor Width ..."
            name="floor_width"
            onChange={handleList}
            value={floor.floor_width}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
          <input
            type="text"
            placeholder="Floor Height ..."
            name="floor_height"
            onChange={handleList}
            value={floor.floor_height}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
        </div>
      </CardBlue> : null}
      <FloorFormButtons spaceID={spaceID} place={place} submitList={submitList} />
    </Fragment>
  );
};
export default FloorForm;
