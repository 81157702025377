import { Link, useRouteMatch } from "react-router-dom";
import CustomPopover from "../../components/Layout/common/CustomPopover";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import api from "../../utils/api";

const SpaceListTableRow = ({ item }) => {
  const { params } = useRouteMatch();
  const [power, setPower] = useState(null);

  useEffect(() => {
    setPower(item.pawer);
  }, []);

  const handlePower = (e) => {
    setPower((prev) => !prev);
  };
  const changePowerMode = async () => {
    const changeSpace = await api.call({
      name : "powerSpace",
      data : {
        space_id : item.id,
        power_bool : power
      }
    })
  }
  useEffect(() => {
    if(power !== null){ 
      changePowerMode()
    }
  } , [power])

  return (
    <>
      <tr
        key={item.id}
        className={`${!item.is_active ? "bg-gray-100" : "odd:bg-blue-50"}`}
      >
        <td className="py-5 px-4">{item.id}</td>
        <td className="py-5">{item.title}</td>
        <td className="py-5">{item.cooling_BTH_per_hour.toFixed(2)}</td>
        <td className="py-5">
          <Link
            to={`/space-add/${params.id}/space/?space=${item.id}`}
            className="text-blue-500"
          >
            Edit
          </Link>
          <button className="text-blue-500 ml-5">Delete</button>
        </td>
        <td className="py-5 flex justify-center items-center">
          <p className="mr-3">{item.register_code}</p>{" "}
          <CustomPopover
            title={<AiOutlineExclamationCircle className="mx-auto" />}
            text={`use this code in physical device`}
          />
        </td>
        <td className="py-5">
          <div className=" relative flex cursor-pointer items-center">
          <label class="relative inline-flex items-center cursor-pointer">
          <input  value={power} onChange={handlePower} type="checkbox" class="sr-only peer" />
          <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        </label>
           <span className="ml-5"> | {!power ? "off" : "on"} </span>
          </div>

        </td>
      </tr>
    </>
  );
};
export default SpaceListTableRow;
