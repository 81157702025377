import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CardBlue from "../../components/Layout/common/CardBlue";
import CardWhite from "../../components/Layout/common/CardWhite";
import api from "../../utils/api";

const Profile = () => {
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
  });
  const [error, setError] = useState({
    phone: false,
    last_name: false,
    first_name: false,
  });
  const validation = (e) => {
    let isValid = true;
    let errorValid = {
      phone: false,
      last_name: false,
      first_name: false,
    };
    if (userData.first_name.trim() === "") {
      isValid = false;
      errorValid["first_name"] = true;
    }
    if (userData.last_name.trim() === "") {
      isValid = false;
      errorValid["last_name"] = true;
    }
    if (userData.phone.trim() === "" || userData.phone.trim().length !== 11) {
      isValid = false;
      errorValid["phone"] = true;
    }
    setError(errorValid);
    return isValid;
  };
  const [userID, setUserID] = useState("");

  const getProfileID = async () => {
    const userInfo = await api.call({
      name: "getUserInfo",
    });
    if (userInfo.data.length) {
      setUserID(userInfo.data[0].id);
      setUserData({
        first_name: userInfo.data[0].first_name,
        last_name: userInfo.data[0].last_name,
        phone: userInfo.data[0].phone,
      });
    }
  };

  const handleValue = (e) => {
    setUserData((prev) => {
        return{
            ...prev,
            [e.target.name] : e.target.value
        }
    })
  }

  const submitForm = async () => {
    if(validation()){
        const changeUserInfo = await api.call({
            name : "changeUserInfo",
            data : userData,
            queryParam : `?id=${userID}`
        })
        if(changeUserInfo.status && changeUserInfo.status === 200){
            toast.success("user data updated", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }
  }

  useEffect(() => {
    getProfileID();
  }, []);
  return (
    <CardWhite bigBox HeightFull>
      <p className="text-2xl">Update User Data</p>
      <div>
        <CardBlue className={"lg:flex-1 w-full lg:w-auto mt-10"}>
          <p className="text-2xl mb-10">Place Information</p>
          <div className="w-9/12 mx-auto">
            <input
              className={`bg-white border  text-[#707070] outline-none rounded block w-full py-3 px-5 mb-7 ${
                error.first_name ? "border-red-500" : "border-[#707070]"
              }`}
              type="text"
              placeholder="First Name ..."
              name="first_name"
              value={userData.first_name}
              onChange={handleValue}
            />
            <input
              className={`bg-white border  text-[#707070] outline-none rounded block w-full py-3 px-5 mb-7 ${
                error.last_name ? "border-red-500" : "border-[#707070]"
              }`}
              type="text"
              placeholder="Last Name ..."
              name="last_name"
              value={userData.last_name}
              onChange={handleValue}
            />
            <input
              className={`bg-white border  text-[#707070] outline-none rounded block w-full py-3 px-5 ${
                error.phone ? "border-red-500" : "border-[#707070]"
              }`}
              type="text"
              placeholder="Phone Number ..."
              name="phone"
              value={userData.phone}
              onChange={handleValue}
            />
            <div className="text-red-500 text-start mb-6"></div>
          </div>
        </CardBlue>
        <div className="flex w-full lg:w-auto flex-col items-center justify-center mt-10">
          <button
            type="submit"
            onClick={submitForm}
            className={`font-medium bg-[#3EE0AF] text-center text-white text-[18px] rounded-md w-[200px] py-3 outline-none mb-5 disabled:opacity-60`}
          >
            Update
          </button>
        </div>
      </div>
    </CardWhite>
  );
};
export default Profile;
