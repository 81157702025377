import { useEffect, useState } from "react";
import api from "../../../utils/api";
import BaseModal from "./BaseModal";

const ModalWeatherInfo = ({ AddWeatherInfo = "" , ToggleModal }) => {
  const [reigion , setReigion] = useState("")
  const [location , setLocation] = useState("")
  const [city , setCity] = useState("")
  const [userID, setUserID] = useState("");
  const [userInfo , setUserInfo] = useState("")
  const [weatherInfo, setWeatherInfo] = useState({
    reigion: "",
    location: "",
    city: "",
  });
  const [disabledItems , setDisabledItems] = useState({
    reigion: false,
    location: true,
    city: true,
  })

  const getWeatherInfo = async () => {
    const weatherInfo = await api.call({
      name : "weatherInfo"
    })
    if(weatherInfo.data && weatherInfo.data.length){
      setReigion(weatherInfo.data)
      getProfileID(weatherInfo.data)
    }
  }

  const getProfileID = async (weatherInfoData) => {
    const userInfo = await api.call({
      name: "getUserInfo",
    });
    let locationItem , cityItem
    let weatherUser = {
      reigion: "",
      location: "",
      city: "",
    }
    if (userInfo.data.length) {
      setUserID(userInfo.data[0].id);
      let disabledListCustom = disabledItems
      setUserInfo({
        first_name : userInfo.data[0].first_name,
        last_name : userInfo.data[0].last_name,
      })
      
      if(userInfo.data[0].reigion){
        weatherUser.reigion = userInfo.data[0].reigion
      }
      if(userInfo.data[0].location){
        weatherUser.location = userInfo.data[0].location
        locationItem = weatherInfoData.find(item => {
          return item.id == userInfo.data[0].reigion
        }).location
        setLocation(locationItem)
        disabledListCustom["location"] = false
      }
      if(userInfo.data[0].city){
        weatherUser.city = userInfo.data[0].city
        cityItem = locationItem.find(item => {
          return item.id == userInfo.data[0].location
        }).city
        setCity(cityItem)
        disabledListCustom["city"] = false
      }
      setWeatherInfo(weatherUser)
    }
  };

  const handleList = (e) => {
    let disabledListCustom = disabledItems
    // location
    if(e.target.name === "reigion" && e.target.value !== ""){
      let b = reigion.find(item => {
        return item.id == e.target.value
      }).location
      setLocation(b)
      setCity("")
      disabledListCustom["location"] = false
      disabledListCustom["city"] = true
    }
    if(e.target.name === "reigion" && e.target.value == ""){
      setLocation("")
      setCity("")
      disabledListCustom["location"] = true
      disabledListCustom["city"] = true
    }
    // city
    if(e.target.name === "location" && e.target.value !== ""){
      let b = location.find(item => {
        return item.id == e.target.value
      }).city
      setCity(b)
      disabledListCustom["city"] = false
    }
    if(e.target.name === "location" && e.target.value == ""){
      setCity("")
      disabledListCustom["city"] = true
    }
    setDisabledItems(disabledListCustom)
    setWeatherInfo({
      ...weatherInfo, 
      [e.target.name]: e.target.value,
    });
  };

  const submitList = () => {
    let isValid = true
    if(weatherInfo.city == ""){
        isValid = false
    }
    if(weatherInfo.reigion == ""){
        isValid = false
    }
    if(weatherInfo.location == ""){
        isValid = false
    }
    if(isValid){
      createNewWeatger()
    }else{

    }
  }

  const createNewWeatger = async () => {
    const weatherInformation = await api.call({
      name : "changeUserInfo",
      data : {...weatherInfo , ...userInfo},
      queryParam : `?id=${userID}`
    })
    if(weatherInformation.data){
      if(AddWeatherInfo){
        AddWeatherInfo(weatherInformation.data)
      }
      ToggleModal()
    }
  }
  
  useEffect(() => {
    getWeatherInfo()
  } , [])
  return (
    <>
    <BaseModal ToggleModal={ToggleModal}>
        <p className="font-normal text-2xl mb-6">Weather Info</p>
        <select
          value={weatherInfo.reigion}
          onChange={handleList}
          name="reigion"
          id="countries"
          className="bg-white text-[#707070] outline-none rounded-sm block w-full py-3 px-5 mb-6"
        >
          <option value={""}>{"reigion"}</option>
          {reigion ? reigion.map(item => <option key={item.title} value={item.id}>{item.title}</option>) : null}
        </select>
        <select
          value={weatherInfo.location}
          onChange={handleList}
          disabled={disabledItems.location}
          name="location"
          className="bg-white text-[#707070] outline-none rounded-sm block w-full py-3 px-5 mb-6"
        >
          <option value={""}>{"Location"}</option>
          {location ? location.map(item => <option key={item.title} value={item.id}>{item.title}</option>) : null}
        </select>
        <select
          value={weatherInfo.city}
          onChange={handleList}
          disabled={disabledItems.city}
          name="city"
          className="bg-white text-[#707070] outline-none rounded-sm block w-full py-3 px-5 mb-12"
        >
          <option value={""}>{"city"}</option>
          {city ? city.map(item => <option key={item.title} value={item.id}>{item.title}</option>) : null}
        </select>
        <button
          onClick={submitList}
          className="bg-[#3EE0AF] font-medium text-white rounded-md py-3 px-20"
        >
          apply
        </button>
    </BaseModal>
    </>
  );
};
export default ModalWeatherInfo;
