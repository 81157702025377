import { Link } from "react-router-dom";
import CardBlue from "../../components/Layout/common/CardBlue";
import CardWhite from "../../components/Layout/common/CardWhite";
import { AiOutlineExclamation } from "react-icons/ai";
import PlaceEditForm from "./components/PlaceEditForm";

const PlaceEdit = () => {
  return (
    <CardWhite bigBox HeightFull>
      <section>
      <p className="text-2xl text-center lg:text-left font-medium">Edit Place</p>
      </section>
      <section>
        <PlaceEditForm />
      </section>
    </CardWhite>
  );
};
export default PlaceEdit;
