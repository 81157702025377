import { useEffect, useState } from "react";
import CardBlue from "../../components/Layout/common/CardBlue";
import CardWhite from "../../components/Layout/common/CardWhite";
import api from "../../utils/api";
import FormWall from "./components/FormWall";
import ListItem from "./components/ListItems";

const Wall = ({ spaceID , place }) => {
  const [itemListData, setItemListData] = useState([]);
  const getListItme = async () => {
    setItemListData([]);
    const wall = await api.call({
      name: "listWall",
    });
    const windows = await api.call({
      name: "listWindows",
    });
    let b = [...wall.data, ...windows.data]
    b = b.map((item , index) => {
      return {...item , customId : index * item.id}
    })
    setItemListData(b);
  };
  const createWallWindowItem = async (data, itemModel) => {
    if (itemModel == 2) {
      const wall = await api.call({
        name: "wall",
        data: data,
      });
      getListItme();
      return wall.status;
    }
    if (itemModel == 1) {
      const windows = await api.call({
        name: "windows",
        data: data,
      });
      getListItme();
      return windows.status;
    }
  };
  useEffect(() => {
    getListItme();
  }, []);
  return (
    <CardWhite className={"box-adding"}>
      <section className="flex flex-wrap h-full">
        <div className="w-full order-2 lg:order-none lg:w-7/12 lg:pr-4">
          <CardBlue className="flex-1 h-full">
            <p className="text-2xl mb-10 text-center">Windows / Wall List</p>
            <div className="mx-auto w-full overflow h-[390px] pr-4 overflow-y-scroll custom-scroll">
              <ListItem getListItme={getListItme} spaceID={spaceID} itemListData={itemListData} />
            </div>
          </CardBlue>
        </div>
        <div className="w-full lg:w-5/12 lg:pl-4 mb-10 lg:mb-0">
          <FormWall
            createWallWindowItem={createWallWindowItem}
            spaceID={spaceID}
            place={place}
          />
        </div>
      </section>
    </CardWhite>
  );
};
export default Wall;
