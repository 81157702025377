import { Fragment, useEffect, useState } from "react";
import { Redirect, Route, Router, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import ChekcAuthRoute from "./Auth/ChekcAuthRoute";
import Profile from "./page/Profile";
import Layout from "./components/Layout";
import Dashbord from "./page/Dashbord";
import Login from "./page/Login";
import PlaceAdd from "./page/PlaceAdd";
import PlaceEdit from "./page/PlaceEdit";
import PlaceList from "./page/PlaceList";
import Signup from "./page/Signup";
import SpaceAdd from "./page/SpaceAdd";
import SpaceList from "./page/SpaceList";
import { config } from "./utils/config";
import BTHSpace from "./page/BTHSpace";
function App() {
  const { pathname } = useLocation();
  const [token, setToken] = useState("");
  const [addNewItem , setAddNewItem] = useState(false)
  const GetLocalToken = () => {
    setToken(config.token());
  };
  useEffect(() => {
    GetLocalToken();
  }, []);

  const ChangeAddNewItem = () => {
    setAddNewItem(true)
  }

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return (
    <Fragment>
      <Switch>
        <Route path={"/login"} exact>
          {token ? <Redirect to={"/"} /> : <Login />}
        </Route>
        <Route exact path={"/singup"}>
          <Signup />
        </Route>
        <Layout addNewItem={addNewItem}>
          
        <ChekcAuthRoute
            render={() => <Dashbord />}
            exact
            token={token}
            path={"/"}
          />
          <ChekcAuthRoute
            render={() => <PlaceList />}
            exact
            token={token}
            path={"/place-list"}
          />
          <ChekcAuthRoute
            render={() => <PlaceAdd ChangeAddNewItem={ChangeAddNewItem}/>}
            exact
            token={token}
            path={"/place-add"}
          />
          <ChekcAuthRoute
            render={() => <PlaceEdit />}
            exact
            token={token}
            path={"/place-edit/:id"}
          />
          <Route path={"/space-add/:place"}>
            <SpaceAdd />
          </Route>
          <ChekcAuthRoute
            render={() => <SpaceList />}
            exact
            token={token}
            path={"/space-list/:id"}
          />
          <ChekcAuthRoute
            render={() => <Profile />}
            exact
            token={token}
            path={"/profile"}
          />
          <ChekcAuthRoute
            render={() => <BTHSpace />}
            exact
            token={token}
            path={"/BTH-space/:id"}
          />
        </Layout>
      </Switch>
      <ToastContainer />
    </Fragment>
  );
}

export default App;
