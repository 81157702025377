import React, { useEffect, useState } from "react";
import api from "../../../../utils/api/index"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    tooltip: {
      mode: "index",
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];


const AirQuality = ({ placeID }) => {
  const [data , setData] = useState("")
  const [chartData , setChartData] = useState("")
  const [mode , setMode] = useState("data-monthly")
  useEffect(() => {
    setData("")
    if(chartData){
      let co2 = []
      let PM2 = []
      let PM10 = []
      let TVOC = ""
      let listLabels = []
      for(let i in chartData.CO2){
        listLabels.push(mode === "data-monthly" ? chartData.CO2[i].year + "-" + chartData.CO2[i].month : chartData.CO2[i].year + "-" + chartData.CO2[i].month + "-" + chartData.CO2[i].day);
        co2.push(chartData.CO2[i].avg_value)
      }
      for(let i in chartData['PM2.5']){
        PM2.push(chartData['PM2.5'][i].avg_value)
      }
      for(let i in chartData.PM10){
        PM10.push(chartData.PM10[i].avg_value)
      }
      setData({
        labels : listLabels,
        datasets: [
          {
            fill: true,
            label: "co2",
            data: co2,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          },
          {
            fill: true,
            label: "PM2.5",
            data: PM2,
            borderColor: "#FFCD56",
            backgroundColor: "#FFCD56ac",
          },
          {
            fill: true,
            label: "PM10",
            data: PM10,
            borderColor: "#4BC0C0",
            backgroundColor: "#4BC0C0ac",
          },
        ],
      })
    }

  } , [chartData])

  const getChartInfo =  async () => {
    let chartDatas = await api.call({
      name : "chartInfo",
      queryParam : `/${mode}/?place=${placeID}`
    })
    setChartData(chartDatas.data);
  }

  useEffect(() => {
    if(placeID){
      getChartInfo()
    }
  } , [placeID , mode])
 
  const handleMode = (e) =>{
    setMode(e.target.value)
  }

  return (
    <div className="h-full">
      <div className="flex justify-between">
      <h1 className="text-2xl text-center md:text-left mb-4">AirQuality</h1>
      <select
            name="mode"
            id="mode"
            onChange={handleMode}
            defaultValue={mode}
            disabled={!data}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-[150px] py-3 px-5 mb-6"
          >
            <option value={"data-monthly"}>
              Monthly
            </option>
            <option value="data-daily">Daily</option>
          </select>
      </div>
      {data && placeID ? <Line height={"130px"} la  options={options} data={data} /> : null}
    </div>
  );
};
export default AirQuality;
