import { useEffect, useState } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import CardWhite from "../../components/Layout/common/CardWhite";
import api from "../../utils/api";

const BTHSpace = () => {
  const route = useRouteMatch();
  const [spaceInformation, setSpaceInformation] = useState("");
  const getSpaceInfo = async () => {
    const spaceInfo = await api.call({
      name: "listSpace",
      queryParam: `?id=${route.params.id}`,
    });
    setSpaceInformation(spaceInfo.data);
  };

  useEffect(() => {
    getSpaceInfo();
  }, []);
  return (
    <CardWhite HeightFull>
      <div className="flex flex-col justify-center items-center h-full w-full">
        <div className="text-center">
          <p className="text-lg">
            Cooling BTH/hour For This Space : {" "}
          </p>
          {spaceInformation ? (
            <p className="text-2xl mt-5">
              {spaceInformation.cooling_BTH_per_hour.toFixed(2)}
            </p>
          ) : null}
        </div>
        <Link
          to={"/"}
          className="mt-5 font-medium hidden md:block bg-[#3EE0AF] text-center text-white text-[16px] rounded-md w-[140px] py-2 mx-3 disabled:opacity-60"
          >
          Dashbord{" "}
        </Link>
      </div>
    </CardWhite>
  );
};
export default BTHSpace;
