import { useEffect } from "react";
import LoginForm from "./LoginForm";
import "./style.scss"
const Login = () => {
  useEffect(() => {
    document.body.classList.add("login-body");
    return () => {
      document.body.classList.remove("login-body");
    };
  }, []);
  return (
    <div className="w-full sm:w-[530px] bg-[rgba(255,255,255,0.8)] py-28 px-16 mx-auto lg:ml-auto lg:mr-24 lg:mt-32 my-10 rounded-md text-center">
        <div className="mb-10">
            <p className="text-[25px] font-bold mb-5">
            Hello Again !
            </p>
            <p className="text-[20px] text-[#4C4C4C]">
            Welcome Back To Intelligent HVAC System
            </p>
        </div>
        <LoginForm />
    </div>
  );
};
export default Login;
