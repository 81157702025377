import { useState } from "react";
import AlertToast from "../../../components/Layout/common/AlertToast";
import BaseModal from "../../../components/Layout/common/BaseModal";

const ModalSpace = ({ ToggleModal }) => {
  const [weatherInfo, setWeatherInfo] = useState({
    equipmentType: "",
    systemType: "",
  });
  const [showError, setShowError] = useState(false);
  const handleList = (e) => {
    setWeatherInfo({
      ...weatherInfo,
      [e.target.name]: e.target.value,
    });
  };
  const ToggleToast = () => {
    setShowError(false);
  };
  const submitList = () => {
    let isValid = true;
    if (weatherInfo.systemType == "") {
      isValid = false;
      setShowError(true);
    }
    if (weatherInfo.equipmentType == "") {
      isValid = false;
      setShowError(true);
    }
    if (isValid) {
      ToggleModal();
    } else {
      setTimeout(() => {
        setShowError(false);
      }, 5000);
    }
  };
  return (
    <>
      <BaseModal ToggleModal={ToggleModal}>
        <p className="font-normal text-2xl mb-6">Weather Info</p>
        <select
          value={weatherInfo.equipmentType}
          onChange={handleList}
          name="equipmentType"
          id="countries"
          className="bg-white text-[#707070] outline-none rounded-sm block w-full py-3 px-5 mb-6"
        >
          <option selected value={""}>
            Equipment type
          </option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
        </select>
        <select
          value={weatherInfo.systemType}
          onChange={handleList}
          name="systemType"
          className="bg-white text-[#707070] outline-none rounded-sm block w-full py-3 px-5 mb-6"
        >
          <option selected value={""}>
            System Type
          </option>
          <option value="US">United States</option>
          <option value="CA">Canada</option>
          <option value="FR">France</option>
          <option value="DE">Germany</option>
        </select>
        <button
          onClick={submitList}
          className="bg-[#3EE0AF] font-medium text-white rounded-md py-3 px-20"
        >
          apply
        </button>
      </BaseModal>
      {showError && <AlertToast ToggleToast={ToggleToast} />}
    </>
  );
};
export default ModalSpace;
