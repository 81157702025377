import { useEffect, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { Link, useRouteMatch } from "react-router-dom";
import CardWhite from "../../components/Layout/common/CardWhite";
import CustomPopover from "../../components/Layout/common/CustomPopover";
import api from "../../utils/api";
import SpaceListTableRow from "./SpaceListTableRow";

const SpaceList = () => {
  const [spaceListPlace, setSpaceListPlace] = useState("");
  const [placeInfo, setPlaceInfo] = useState("");
  const { params } = useRouteMatch();

  const getPlaceItem = async () => {
    const placeList = await api.call({
      name: "placeList",
    });
    if (placeList.data.length) {
      let thisPlace = placeList.data.find((item) => {
        return item.id == params.id;
      });
      setPlaceInfo(thisPlace);
      setSpaceListPlace(thisPlace.space);
    }
  };
  useEffect(() => {
    getPlaceItem();
  }, []);
  return (
    <CardWhite bigBox HeightFull>
      <div className="flex justify-between flex-col h-full">
        <div>
          <p className="text-2xl font-medium">
            {placeInfo ? placeInfo.title : null} - Space List
          </p>
        </div>
        {spaceListPlace.length ? (
          <table className="w-full mt-16">
            <tbody>
              <tr className="text-sm border-b text-left border-black">
                <th className="pb-5">#</th>
                <th className="pb-5">Name</th>
                <th className="pb-5">Cooling BTH/Hr</th>
                <th className="pb-5">options</th>
                <th className="pb-5">Register Code</th>
                <th className="pb-5">Power</th>
              </tr>
              {spaceListPlace
                ? spaceListPlace.map((item) => {
                    return (
                      <SpaceListTableRow key={item.id} item={item}/>
                    );
                  })
                : null}
            </tbody>
          </table>
        ) : (
          <p className="my-7 text-center text-3xl">
            You Have Not Space For This Place
          </p>
        )}
        <div className="mt-auto text-right">
          <Link
            to={`/space-add/${params.id}/space`}
            className="font-medium inline-block bg-[#3EE0AF] text-center text-white text-[18px] rounded-md w-[200px] py-3 outline-none mb-5 mt-10 disabled:opacity-60"
          >
            Add Space
          </Link>
        </div>
      </div>
    </CardWhite>
  );
};
export default SpaceList;
