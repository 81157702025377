import { useState } from "react";
import { Link } from "react-router-dom";
import CardWhite from "../../../../components/Layout/common/CardWhite";
import ModalWeatherInfo from "../../../../components/Layout/common/ModalWeatherInfo";
import ChangePlaceModal from "../ChangePlaceModal";

  const PlaceItem = ({ changePlaceInfo , plcaeListItem , placeID ,  plcaeInfo }) => {
  const [showModal, setShowModal] = useState(false);
  const ToggleModal = () => {
    setShowModal((prev) => {
      return !prev;
    });
  };
  const [showModalWeather, setShowModalWeather] = useState(false);
  const ToggleModalWeather = () => {
    setShowModalWeather((prev) => {
      return !prev;
    });
  };
  return (
    <>
      <CardWhite>
        <div className="flex items-baseline mb-6">
          <p className="text-2xl">Your Place</p>
          <button
            onClick={ToggleModal}
            className="bg-blue-700 ml-2 text-white text-xs p-1 rounded"
          >
            Change Place
          </button>
          <Link
            to={"/place-add"}
            className="bg-blue-700 ml-2 text-white text-xs p-1 rounded"
          >
            Add New Place
          </Link>
        </div>
        <div className="mb-2">
          {Object.keys(plcaeInfo).length ? (
            <div className="mx-4">
              <p className="mb-1 text-gray-600">{plcaeInfo.title}</p>
              <Link
                to={`/place-edit/${placeID}`}
                className="text-xs text-blue-500"
              >
                Edit
              </Link>
              <Link
                to={`/space-list/${placeID}`}
                className="text-xs text-blue-500 ml-5"
              >
                Space List
              </Link>
            </div>
          ) : null}
        </div>
        <div className="flex justify-end">

          <button
            onClick={ToggleModalWeather}
            className="font-medium mb-4 md:mb-0 bg-[#FFB95C] text-white text-[16px] rounded-md w-full md:w-[140px] py-2 mx-3"
          >
            Weather Info
          </button>
          <Link
            to={`/space-add/${placeID}/space`}
            className="font-medium hidden md:block bg-[#3EE0AF] text-center text-white text-[16px] rounded-md w-[140px] py-2 mx-3 disabled:opacity-60"
          >
            Add Space
          </Link>
        </div>
      </CardWhite>
      {showModal && plcaeListItem && <ChangePlaceModal changePlaceInfo={changePlaceInfo} placeID={placeID} plcaeListItem={plcaeListItem} ToggleModal={ToggleModal} />}
      {showModalWeather && <ModalWeatherInfo ToggleModal={ToggleModalWeather}/>}
    </>
  );
};
export default PlaceItem;
