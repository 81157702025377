import { useEffect, useState } from "react";
import CardBlue from "../../components/Layout/common/CardBlue";
import CardWhite from "../../components/Layout/common/CardWhite";
import api from "../../utils/api";
import EquipmentForm from "./components/EquipmentForm";
import EquipmentList from "./components/EquipmentList";

const Equipment = ({ spaceID , place }) => {
  const [itemListData , setItemListData] = useState("")
  
  const getListItme = async () => {
    setItemListData([]);
    const equipmentListData = await api.call({
      name: "listEquipment",
    });
    setItemListData(equipmentListData.data);
  };

  const createEquipment = async (data) => {
      let custom = {}
      for(let i in data){
        if(data[i] !== ""){
          custom = {...custom , [i] : data[i]}
        }
      }
      const equipmentCreate = await api.call({
        name: "equipmentCreate",
        data: {...custom , space : spaceID},
      });
      getListItme();
      return equipmentCreate.status;
  };
  useEffect(() => {
    getListItme();
  }, []);

  return (
    <CardWhite className={"box-adding"}>
    <section className="flex flex-wrap h-full">
      <div className="w-full order-2 lg:order-none lg:w-7/12 lg:pr-4">
        <CardBlue className="flex-1 h-full">
          <p className="text-2xl mb-10 text-center">Equipment List</p>
          <div className="mx-auto w-full overflow h-[390px] pr-4 overflow-y-scroll custom-scroll">
              {itemListData ? <EquipmentList getListItme={getListItme} spaceID={spaceID} itemListData={itemListData} /> : null}
            </div>
        </CardBlue>
      </div>
      <div className="w-full lg:w-5/12 lg:pl-4 mb-10 lg:mb-0">
        <EquipmentForm place={place} spaceID={spaceID} createEquipment={createEquipment}/>
      </div>
    </section>    
    </CardWhite>
  );
};
export default Equipment;
