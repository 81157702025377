import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import api from "../../../utils/api";
import "./style.scss"
const Sidebar = ({ closeSlider ,addNewItem}) => {
  const location = useLocation();
  const history = useHistory()
  const [hasItem , setHasItem] = useState(true) 
  const Logout = () => {
    localStorage.clear()
    history.push("/login")
    window.location.reload()
  }
  const getplaceList = async () => {
    const place =  await api.call({
        name : "placeList"
    })
    setHasItem(place.data.length);
}
useEffect(() => {
  getplaceList()
} , []) 
  return (
    <div className="h-full">
      <div className="border border-l-0 border-t-0 border-r-0 pb-5">
        <img src="/calculation/static/logo.png" className="mx-auto w-[130px]" alt="logo" />
      </div>
      <ul className="mt-5">
        {!hasItem && !addNewItem ? <li>
            <Link onClick={closeSlider} className={`text-[17px] rounded-md inline-block mb-1 px-3 py-1 ${location.pathname == "/place-list" ? " text-white bg-[#429AF1]" : ""}`} to={"/place-list"}>
                Place List
            </Link>
        </li> : null}
        {hasItem || addNewItem ? <li>
            <Link onClick={closeSlider} className={`text-[17px] rounded-md inline-block mb-1 px-3 py-1 ${location.pathname == "/" ? " text-white bg-[#429AF1]" : ""}`} to={"/"}>
                Dashbord
            </Link>
        </li> : null}
        <li>
            <Link onClick={closeSlider} className={`text-[17px] rounded-md inline-block mb-1 px-3 py-1 ${location.pathname == "/profile" ? " text-white bg-[#429AF1]" : ""}`} to={"/profile"}>
                Profile
            </Link>
        </li>     
        <li>
            <Link onClick={() => {
                closeSlider()
                Logout()
            }} className={`text-[17px] rounded-md inline-block mb-1 px-3 py-1 ${location.pathname == "/login" ? " text-white bg-[#429AF1]" : ""}`} to={"/login"}>
               Logout
            </Link>
        </li>
      </ul>
    </div>
  );
};
export default Sidebar;
