import { useState } from "react";
import CardBlue from "../../../components/Layout/common/CardBlue";
import EquipmentFormButtons from "./EquipmentFormButtons"
const EquipmentForm = ({ place , spaceID, createEquipment }) => {
  const [equipment, setEquipment] = useState({
    type: "",
    title: "",
    KW_per_ft2: null,
    number_of_person: null,
  });
  const handleList = (e) => {
    if (e.target.name === "type") {
      setEquipment({
        ...equipment,
        [e.target.name]: e.target.value,
        KW_per_ft2: "",
        number_of_person: "",
      });
    } else if (e.target.name === "KW_per_ft2" || e.target.name === "number_of_person") {
      setEquipment({
        ...equipment,
        [e.target.name]: parseInt(e.target.value),
      });
    }
    else {
      setEquipment({
        ...equipment,
        [e.target.name]: e.target.value,
      });
    }
  };
  const postForm = async (data) => {
    const res = await createEquipment(data);
    if (res == 201) {
      setEquipment({
        type: "",
        title: "",
        KW_per_ft2: "",
        number_of_person: "",
      });
    }
  };
  const submitList = () => {
    let isValid = true;
    if (equipment.title == "") {
      isValid = false;
    }
    if (equipment.type == "") {
      isValid = false;
    }
    if (equipment.type === "l" && equipment.KW_per_ft2 == "") {
      isValid = false;
    }
    if (equipment.type === "p" && equipment.number_of_person === "") {
      isValid = false;
    }
    if (isValid) {
      postForm(equipment)
    }
  };
  return (
    <div className="flex flex-col justify-between">
      <CardBlue className="text-center order-2 lg:order-none">
        <p className="text-2xl mb-10">Add Equipment</p>
        <select
          name="type"
          id="countries"
          onChange={handleList}
          value={equipment.type}
          className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
        >
          <option value={""}>
            Select Equipment Type
          </option>
          <option value="l">Light</option>
          <option value="p">People</option>
        </select>
        <input
          type="title"
          onChange={handleList}
          value={equipment.title}
          name="title"
          placeholder="Title ..."
          className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
        />
        {equipment.type ? (
          <input
            name={equipment.type === "l" ? "KW_per_ft2" : "number_of_person"}
            onChange={handleList}
            value={
              equipment.type === "l"
                ? equipment.KW_per_ft2
                : equipment.number_of_person
            }
            placeholder={
              equipment.type === "l"
                ? "KW Per ft2 ...."
                : "Number of Person ..."
            }
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
        ) : null}
        <button
          onClick={submitList}
          className="bg-[#3EE0AF] font-medium text-white rounded-md py-3 px-20"
        >
          Add
        </button>
      </CardBlue>
      <div className="flex text-center w-full mb-10 lg:mb-0 lg:mt-5">
        <EquipmentFormButtons place={place} spaceID={spaceID}/>
      </div>
    </div>
  );
};
export default EquipmentForm;
