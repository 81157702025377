import api from "../../../utils/api";

const WallItem = ({getListItme , item}) => {
    const deletedItem = async (id = "" , typeItem = "") => {
        if(typeItem === "wall"){
            const wallItemDeleted = await api.call({
                name : "deletedWallItem",
                queryParam : `?id=${id}`
            })
        }
        if(typeItem === "windows"){
            const WindowsItemDeleted = await api.call({
                name : "deletedWindowsItem",
                queryParam : `?id=${id}`
            })
        }
        getListItme()
    }
    return(
        <div className="bg-white text-left py-3 px-4 mb-4 relative rounded-lg">
            <p className="mb-3 text-lg">
                {item.itemModel}
            </p>
            <ul className="ml-5">
                <li className="mb-1 text-sm">Geographical direction : {item.geographical_direction}</li>
                <li className="mb-1 text-sm">width : {item.width}</li>
                <li className="mb-1 text-sm">height  : {item.height}</li>
                <li className="mb-1 text-sm">space  : {item.space}</li>
                <button onClick={() => deletedItem(item.id , item.type)} className="absolute right-3 bottom-3  text-[13px] text-red-500">Delete</button>
            </ul>
        </div>
    )
}
export default WallItem