import { Redirect, Route } from "react-router-dom";
import { config } from "../utils/config";
const ChekcAuthRoute = (props) => {
  return (
    <>
      <Route>
        {() => {
          const tokenLocal = config.token();
          if (tokenLocal) {
            return <Route {...props} />;
          } else {
            return <Redirect to="/login" />;
          }
        }}
      </Route>
    </>
  );
};
export default ChekcAuthRoute;
