import { Fragment, useEffect, useState } from "react";
import { AiOutlineExclamation } from "react-icons/ai";
import CardBlue from "../../../components/Layout/common/CardBlue";
import SpaceFormButtons from "./SpaceFormButtons";

const SpaceForm = ({handleMainSpaceValue , spaceInfo , place , spaceID}) => {
  const [space, setSpace] = useState({
    title: "",
    device_serial_ID: "",
    sq2: "",
    ceiling_height: "",
  });

  useEffect(() => {
    if(spaceInfo){
     setSpace({
      title: spaceInfo.title,
      device_serial_ID: spaceInfo.device_serial_ID,
      sq2: spaceInfo.sq2,
      ceiling_height: spaceInfo.ceiling_height,
    })
    }
  }, [spaceInfo])

  const handleList = (e) => {
    setSpace({
      ...space,
      [e.target.name]: e.target.value,
    });
  };
  const submitList = () => {
    let isValid = true;
    if (space.title == "") {
      isValid = false;
    }
    if (space.device_serial_ID == "") {
      isValid = false;
    }
    if (space.sq2 == "") {
      isValid = false;
    }
    if (space.ceiling_height == "") {
      isValid = false;
    }
    if (isValid) {
      handleMainSpaceValue(space);
    }
  };
  
  return (
    <Fragment>
      <CardBlue className={"h-full w-full lg:flex-1 order-2 lg:order-none"}>
        <p className="text-2xl mb-10">Place Information</p>
        <div className="w-9/12 mx-auto mb-10">
          <input
            type="text"
            placeholder="Space Title ..."
            name="title"
            onChange={handleList}
            value={space.title}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
          <input
            type="text"
            placeholder="Ceiling Height ..."
            name="ceiling_height"
            onChange={handleList}
            value={space.ceiling_height}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
          <input
            type="text"
            placeholder="SQ2 ..."
            name="sq2"
            onChange={handleList}
            value={space.sq2}
            className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5 mb-6"
          />
          <div className="relative">
            <span className="bg-[#3EE0AF] inline-block absolute top-3 right-3 rounded-full p-1">
              <AiOutlineExclamation />
            </span>
            <input
              type="text"
              placeholder="Device Serial ID"
              name="device_serial_ID"
              onChange={handleList}
              value={space.device_serial_ID}
              className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-full py-3 px-5"
            />
          </div>
        </div>
        {/* <button onClick={ToggleModal} className="text-sm text-[#3E90E0]">
          add custom Equipment/System Type for this space
        </button> */}
      </CardBlue>
      <SpaceFormButtons spaceID={spaceID} place={place} submitList={submitList} />
    </Fragment>
  );
};
export default SpaceForm;
