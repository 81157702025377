import CardWhite from "../../components/Layout/common/CardWhite";
import PlaceAddForm from "./components/PlaceAddForm";

const PlaceAdd = ({ChangeAddNewItem}) => {
  return (
    <CardWhite bigBox HeightFull>
      <section>
        <p className="text-2xl text-center lg:text-left font-medium">Add Place</p>
      </section>
      <section>
        <PlaceAddForm ChangeAddNewItem={ChangeAddNewItem}/>
      </section>
    </CardWhite>
  );
};
export default PlaceAdd;
