import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import api from "../../../../utils/api";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController
);

export const options = {
  responsive: true,
};

export function EnergyMeter({ placeID }) {
  const [data, setData] = useState("");
  const [chartData, setChartData] = useState("");
  const [mode, setMode] = useState("data-monthly");
  useEffect(() => {
    setData("");
    if (chartData) {
      let Energy_Meter = [];
      let listLabels = [];
      for (let i in chartData["Energy Meter"]) {
        listLabels.push(
          mode === "data-monthly"
            ? chartData["Energy Meter"][i].year +
                "-" +
                chartData["Energy Meter"][i].month
            : chartData["Energy Meter"][i].year +
                "-" +
                chartData["Energy Meter"][i].month +
                "-" +
                chartData["Energy Meter"][i].day
        );
        Energy_Meter.push(chartData["Energy Meter"][i].avg_value);
      }
      setData({
        labels: listLabels,
        datasets: [
          {
            type: "bar",
            label: "Dataset 2",
            backgroundColor: "#FFA0B4",
            data: Energy_Meter,
            borderColor: "white",
            borderWidth: 2,
          },
        ],
      });
    }
  }, [chartData]);

  const getChartInfo = async () => {
    let chartDatas = await api.call({
      name: "chartInfo",
      queryParam: `/${mode}/?place=${placeID}`,
    });
    setChartData(chartDatas.data);
  };

  useEffect(() => {
    if (placeID) {
      getChartInfo();
    }
  }, [placeID, mode]);

  const handleMode = (e) => {
    setMode(e.target.value);
  };
  return (
    <div className="h-full">
      <div className="flex justify-between">
        <h1 className="text-2xl mb-4 text-center md:text-left">Energy Meter</h1>{" "}
        <select
          name="mode"
          id="mode"
          onChange={handleMode}
          disabled={!data}
          defaultValue={mode}
          className="bg-white border border-[#707070] text-[#707070] outline-none rounded block w-[150px] py-3 px-5 mb-6"
        >
          <option value={"data-monthly"}>
            Monthly
          </option>
          <option value="data-daily">Daily</option>
        </select>
      </div>
      {data && placeID ? (
        <Chart height={"130px"} type="bar" options={options} data={data} />
      ) : null}
    </div>
  );
}
